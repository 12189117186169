<template>
  <v-form
    v-model="valid"
  >
    <v-expansion-panels
      mandatory
      flat
    >
      <v-expansion-panel
        v-for="(panel, i) in panels"
        :key="i"
      >
        <v-expansion-panel-header>{{ panel.title }}</v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-row dense>
            <v-col
              :cols="field.cols || '12'"
              v-for="(field, index) in panel.fields"
              :key="index"
            >
              <v-text-field
                v-if="field.type === 'text'"
                dense
                :label="field.required ? field.label + ' *' : field.label"
                :type="field.type"
                filled
                :required="field.required"
                v-model="value[field.key]"
                :prepend-icon="field.icon"
                :rules="field.rules"
                :hint="field.hint"
                :persistent-hint="field.persistentHint"
              />
              <v-text-field
                v-if="field.type === 'number'"
                dense
                :label="field.required ? field.label + ' *' : field.label"
                :type="field.type"
                filled
                :required="field.required"
                v-model="value[field.key]"
                :prepend-icon="field.icon"
                :rules="field.rules"
                :hint="field.hint"
                :persistent-hint="field.persistentHint"
              />

              <v-checkbox
                v-if="field.type === 'checkbox'"
                dense
                :label="field.required ? field.label + ' *' : field.label"
                filled
                :required="field.required"
                v-model="value[field.key]"
                :prepend-icon="field.icon"
                :key="forceRenderKey"
                :rules="field.rules"
              />

              <v-select
                v-if="field.type === 'select'"
                dense
                :label="field.required ? field.label + ' *' : field.label"
                filled
                :required="field.required"
                v-model="value[field.key]"
                :items="field.options"
                item-value="key"
                item-text="label"
                :prepend-icon="field.icon"
                :rules="field.rules"
                :multiple="field.multiple"
                :hint="field.hint"
                :persistent-hint="field.persistentHint"
              />

              <v-autocomplete
                v-if="field.type === 'autocomplete'"
                dense
                :label="field.required ? field.label + ' *' : field.label"
                filled
                :items="field.options"
                item-text="label"
                item-value="key"
                :required="field.required"
                v-model="value[field.key]"
                :prepend-icon="field.icon"
                :no-data-text="$t('couldNotFindValue')"
                :rules="field.rules"
								:hint="field.hint"
                :persistent-hint="field.persistentHint"
              />
              <date-and-time-selection
                v-if="field.type === 'datetime'"
                :label="field.required ? field.label + ' *' : field.label"
                v-model="value[field.key]"
                dense
                filled
                :required="field.required"
                no-icon="true"
                @setDateTime="(timestamp) => value[field.key] = timestamp "
              />

            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>

<script>
import DateAndTimeSelection from '../common/DateAndTimeSelection.vue'
export default {
  name: 'PrinterForm',
  props: {
      value: {
          type: Object,
          required: true,
          default: () => {}
      },
      triggerUpdate: {
          type: Boolean,
          default: false
      },
  },
  components: {
    DateAndTimeSelection
  },
  data() {
    return {
      valid: false
    }
  },
  computed: {
    panels() {
      return [
        {
          title: this.$t('information'),
          description: this.$t('printerInformation'),
          icon: 'mdi-domain',
          fields: [
            {
              key: 'title',
              label: this.$t('title'),
              type: 'text',
              required: true,
              rules: [this.rules.required]
            },
            {
              key: 'model',
              label: this.$t('model'),
              type: 'select',
              required: true,
              options: [
                  'star_mcp_2',
                  'star_mcp_3'
              ],
              rules: [this.rules.required]
            },
            {
              key: 'ipAddress',
              label: this.$t('ipAddress'),
              type: 'text',
              required: false
            },
            {
              key: 'macAddress',
              label: this.$t('macAddress'),
              type: 'text',
              required: false
            }
          ]
        },

        {
          title: this.$t('kitchenReceiptConfiguration'),
          description: this.$t('printKitchenReceiptDescription'),
          icon: 'mdi-domain',
          fields: [
            {
              key: 'printKitchenReceipt',
              label: this.$t('printKitchenReceipt'),
              type: 'checkbox',
            },
            {
              key: 'channels',
              label: this.$t('channels'),
              type: 'select',
              required: false,
              multiple: true,
              options: this.channels,
							hint: this.$t('ifConfiguredPrinterWillOnlyPrintOrderReceiptsForSelectedChannels'),
							persistentHint: true
            },
            {
              key: 'locations',
              label: this.$t('locations'),
              type: 'select',
              required: false,
              multiple: true,
              options: this.locations,
							hint: this.$t('ifConfiguredPrinterWillOnlyPrintOrderReceiptsForSelectedLocations'),
							persistentHint: true
            },
						{
              key: 'categories',
              label: this.$t('categories'),
              type: 'select',
              required: false,
              options: this.categories,
              multiple: true,
							hint: this.$t('filterReceiptLineItemsByCategories'),
							persistentHint: true
            },
            {
              key: 'excludeQrCode',
              label: this.$t('excludeQrCode'),
              type: 'checkbox',
            }
          ]
        }

      ]
    },
    channels() {
      return this.$store.state.channels.map(c => {
				return {
          key: c.uuid,
          label: c.label,
				}

			})
    },
    locations() {
      return this.$store.state.locations.map(l => {
        return {
          key: l.uuid,
          label: l.label
        }
      })
    },
    categories() {
        let categories = this.$store.state.categories
        categories = categories.filter(category => category && category.administrative !== true && category.active !== false)
        if(Array.isArray(categories) && categories.length > 0) {
            return this.$store.getters.categoriesFormatForUiFields(categories)
        }
        return []
    },
    rules() {
      return this.$store.getters.rules
    },
  },
  watch: {
    triggerUpdate(value) {
      if(value === true) {
        return this.$store.dispatch('updatePrinter', this.value).then(res => {
          if(res) {
            this.$store.commit('updateActionSuccess', {
              message: this.$t('success'),
              subMessage: this.$t('successfullyUpdatedPrinter')
            })
            this.$emit('updateProfile', res)
          } else {
            this.$store.commit('updateActionError', {
              message: this.$t('error'),
              subMessage: this.$t('somethingWentWrong')
            })
          }
        }).catch(() => {
          this.$store.commit('updateActionError', {
            message: this.$t('error'),
            subMessage: this.$t('somethingWentWrong')
          })
        }).finally(() => {
          this.$emit('finishedUpdating')
        })
      }
    },
    valid(value) {
        this.$emit('valid', value)
    }
  }
}
</script>

<style>
      .v-expansion-panel {
      border: thin solid rgba(0, 0, 0, 0.12);
      border-radius: 8px
    }

    .v-expansion-panels {
      border-radius: 8px
    }
</style>
